import IntegrationModal from 'APP/components/IntegrationTasks/IntegrationModal';
import Privileges from 'APP/enums/Privileges/Privileges';
import { NEW_APPLICATION_VIEW_BUSINESS, NEW_APPLICATION_VIEW_PRIVATE, QUEUE_CORE } from 'APP/featureFlags';
import PrivilegesHelper, { UserRoleEnums } from 'APP/helpers/PrivilegesHelper';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Switch, withRouter } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

// Routes
const ApplicationCreatePage = React.lazy(() => import('../pages/Application/Create').catch(() => location.reload()));
const MortgageApplicationCreatePage = React.lazy(() =>
    import('../pages/MortgageApplication/Create').catch(() => location.reload())
);
const MortgageApplicationEditPage = React.lazy(() =>
    import('../pages/MortgageApplication/Edit').catch(() => location.reload())
);
const MortgageApplicationDuplicateAndCreatePage = React.lazy(() =>
    import('../pages/MortgageApplication/Create').catch(() => location.reload())
);
const BusinessApplicationCreatePage = React.lazy(() =>
    import('../pages/BusinessApplication/Create').catch(() => location.reload())
);
const Applications = React.lazy(() => import('../pages/Application/List').catch(() => location.reload()));
const ApplicationUpdatePage = React.lazy(() => import('../pages/Application/Update').catch(() => location.reload()));
const ApplicationView = React.lazy(() => import('../pages/Application/View').catch(() => location.reload()));
const PrivateApplicationView = React.lazy(() => import('../pages/PrivateApplication').catch(() => location.reload()));
const BusinessApplicationView = React.lazy(() => import('../pages/BusinessApplication').catch(() => location.reload()));
const MortgageApplicationView = React.lazy(() => import('../pages/MortgageApplication').catch(() => location.reload()));
const MortgageAdminConversationView = React.lazy(() =>
    import('../pages/MortgageAdminConversationView').catch(() => location.reload())
);
const MortgageAdminComplementsView = React.lazy(() =>
    import('../pages/MortgageAdminComplementsView').catch(() => location.reload())
);
const ChangePasswordPage = React.lazy(() => import('../pages/ChangePassword').catch(() => location.reload()));
const CallqueuesPage = React.lazy(() => import('../pages/Callqueues').catch(() => location.reload()));
const MortgageCallqueuesPage = React.lazy(() => import('../pages/MortgageCallqueues').catch(() => location.reload()));
const QueuesPage = React.lazy(() => import('../pages/Queues').catch(() => location.reload()));
const CustomerUserPage = React.lazy(() => import('../pages/CustomerUser').catch(() => location.reload()));
const CompanyPage = React.lazy(() => import('../pages/Company').catch(() => location.reload()));
const DataDeletionPage = React.lazy(() => import('../pages/DataDeletion').catch(() => location.reload()));
const Dashboard = React.lazy(() => import('../pages/Dashboard').catch(() => location.reload()));
const Ownerships = React.lazy(() => import('../pages/Ownerships').catch(() => location.reload()));
const Notifications = React.lazy(() => import('../pages/Notifications').catch(() => location.reload()));
const ListDead = React.lazy(() => import('../pages/MessageScheduler/ListDead').catch(() => location.reload()));
const ListJobs = React.lazy(() => import('../pages/MessageScheduler/ListJobs').catch(() => location.reload()));
const Blacklist = React.lazy(() => import('../pages/Consent/Blacklist').catch(() => location.reload()));
const PartnerPage = React.lazy(() => import('../pages/Partner').catch(() => location.reload()));
const PartnerAdminUsers = React.lazy(() => import('../pages/PartnerAdminUsers').catch(() => location.reload()));
const PartnerProductPage = React.lazy(() => import('../pages/PartnerProduct').catch(() => location.reload()));
const PartnersPage = React.lazy(() => import('../pages/Partners').catch(() => location.reload()));
const Integrations = React.lazy(() => import('../pages/Integrations').catch(() => location.reload()));
const IntegrationPage = React.lazy(() => import('../pages/Integration').catch(() => location.reload()));
const ViewRole = React.lazy(() => import('../pages/Role/View').catch(() => location.reload()));
const ScorePage = React.lazy(() => import('../pages/Score').catch(() => location.reload()));
const StatisticsPage = React.lazy(() => import('../pages/Statistics').catch(() => location.reload()));
const Templates = React.lazy(() => import('../pages/Templates').catch(() => location.reload()));
const UploadCSV = React.lazy(() => import('../pages/UploadCSV').catch(() => location.reload()));
const Users = React.lazy(() => import('../pages/Users').catch(() => location.reload()));
const Campaigns = React.lazy(() => import('../pages/Campaigns').catch(() => location.reload()));
const Reports = React.lazy(() => import('../pages/Reports').catch(() => location.reload()));
const ListTrackingSourceGroups = React.lazy(() =>
    import('../pages/Tracking/SourceGroups').catch(() => location.reload())
);
const ListTrackingMasterGroups = React.lazy(() =>
    import('../pages/Tracking/MasterGroups').catch(() => location.reload())
);
const ListTrackingMainGroups = React.lazy(() => import('../pages/Tracking/MainGroups').catch(() => location.reload()));
const ListTrackingSources = React.lazy(() => import('../pages/Tracking/Sources').catch(() => location.reload()));
const ViewInsurance = React.lazy(() => import('../pages/Insurance/View').catch(() => location.reload()));

//this view is for a separate insurance product on landing page www.lendo.se/trygghetsforsakring
const ViewInsuranceSeparateProduct = React.lazy(() =>
    import('../pages/Insurance/v2/index').catch(() => location.reload())
);

function AdminRoutes(props) {
    const { isAuthenticated, authState, location } = props;

    return (
        <Fragment>
            <Switch>
                <PrivateRoute
                    exact
                    path="/"
                    component={Dashboard}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute path="/users" component={Users} isAuthenticated={isAuthenticated} authState={authState} />
                <PrivateRoute
                    exact
                    path="/change-password"
                    component={ChangePasswordPage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/customer/:id/view"
                    component={CustomerUserPage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/company/:id/view"
                    component={CompanyPage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/applications"
                    component={Applications}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/ownerships"
                    component={Ownerships}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                {PrivilegesHelper.hasAccess(Privileges.MORTGAGE_NOTIFICATIONS_BASIC) && (
                    <PrivateRoute
                        exact
                        path="/notifications"
                        component={Notifications}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                )}
                <PrivateRoute
                    exact
                    path="/application"
                    component={ApplicationCreatePage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                {PrivilegesHelper.hasAccess(Privileges.MORTGAGE_APPLICATION_MANAGE) && (
                    <PrivateRoute
                        exact
                        path="/application/mortgage/create"
                        component={MortgageApplicationCreatePage}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                )}
                <PrivateRoute
                    exact
                    path="/application/:id/update"
                    component={ApplicationUpdatePage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/application/:applicationType/:id/update"
                    component={ApplicationUpdatePage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />

                {NEW_APPLICATION_VIEW_PRIVATE ? (
                    <PrivateRoute
                        path="/application/private/:id"
                        component={PrivateApplicationView}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                ) : (
                    <PrivateRoute
                        path="/application/private/:id"
                        type="private"
                        component={ApplicationView}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                )}

                {NEW_APPLICATION_VIEW_BUSINESS ? (
                    <PrivateRoute
                        path="/application/business/:id"
                        component={BusinessApplicationView}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                ) : (
                    <PrivateRoute
                        path="/application/business/:id"
                        type="business"
                        component={ApplicationView}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                )}

                <PrivateRoute
                    path="/application/business/"
                    component={BusinessApplicationCreatePage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />

                {PrivilegesHelper.hasAccess(Privileges.MORTGAGE_APPLICATION_MANAGE) && (
                    <PrivateRoute
                        path="/application/mortgage/treat/:id"
                        component={MortgageApplicationView}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                )}

                {PrivilegesHelper.hasAccess(Privileges.MORTGAGE_APPLICATION_MANAGE) && (
                    <PrivateRoute
                        path="/application/mortgage/conversations/:id"
                        component={MortgageAdminConversationView}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                )}

                {PrivilegesHelper.hasAccess(Privileges.MORTGAGE_APPLICATION_MANAGE) && (
                    <PrivateRoute
                        path="/application/mortgage/complements/:applicationId/:offerId"
                        component={MortgageAdminComplementsView}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                )}

                {PrivilegesHelper.hasAccess(Privileges.MORTGAGE_APPLICATION_MANAGE) && (
                    <PrivateRoute
                        exact
                        path="/application/mortgage/edit/:id"
                        component={MortgageApplicationEditPage}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                )}

                {PrivilegesHelper.hasAccess(Privileges.MORTGAGE_APPLICATION_MANAGE) && (
                    <PrivateRoute
                        exact
                        path="/application/mortgage/create/:id"
                        component={MortgageApplicationDuplicateAndCreatePage}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                )}
                <PrivateRoute
                    exact
                    path="/statistics"
                    component={StatisticsPage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/reports"
                    component={Reports}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/callqueues"
                    component={CallqueuesPage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/mortgage-callqueues"
                    component={MortgageCallqueuesPage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                {QUEUE_CORE || location.search.includes('queueCoreManagement=on') ? (
                    <PrivateRoute
                        path="/queues"
                        component={QueuesPage}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                ) : null}
                <PrivateRoute
                    exact
                    path="/partners"
                    component={PartnersPage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/partner/:id"
                    component={PartnerPage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/partner-product/:id"
                    component={PartnerProductPage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    path="/integrations"
                    component={Integrations}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/integration/:id"
                    component={IntegrationPage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/score"
                    component={ScorePage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/role/:id"
                    component={ViewRole}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/partneradmins"
                    component={PartnerAdminUsers}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/upload-csv"
                    component={UploadCSV}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/templates"
                    component={Templates}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/scheduler/dead"
                    component={ListDead}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/scheduler/jobs"
                    component={ListJobs}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/consent/blacklist"
                    component={Blacklist}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/tracking/sourcegroups"
                    component={ListTrackingMasterGroups}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/tracking/sourcegroups/main/:id"
                    component={ListTrackingMainGroups}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/tracking/sourcegroups/main/:id/groups"
                    component={ListTrackingSourceGroups}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/tracking/sourcegroups/:id"
                    component={ListTrackingSources}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/insurances/:id"
                    component={ViewInsurance}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/insurances/v2/:id"
                    component={ViewInsuranceSeparateProduct}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                <PrivateRoute
                    exact
                    path="/data-deletions/:id"
                    component={DataDeletionPage}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
                {PrivilegesHelper.hasRole(UserRoleEnums.ADMIN_SUPERUSER) && (
                    <PrivateRoute
                        exact
                        path="/campaigns"
                        component={Campaigns}
                        isAuthenticated={isAuthenticated}
                        authState={authState}
                    />
                )}
                <PrivateRoute component={() => <div />} isAuthenticated={isAuthenticated} authState={authState} />
            </Switch>
            <IntegrationModal />
        </Fragment>
    );
}

AdminRoutes.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    authState: PropTypes.string.isRequired,
};

export default withRouter(AdminRoutes);
